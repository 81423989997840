import React from "react";

const AccountButton = ({
  clickLabel,
  handleClick,
  twClass,
  buttonTwClass,
  isLoading,
}) => {
  return (
    <div className={`${twClass}`}>
      <button
        className={`${buttonTwClass} w-full p-1`}
        onClick={handleClick}
        disabled={isLoading} // Disable the button while loading
      >
        {isLoading ? (
          <div className="flex items-center justify-center">
            <div className="animate-spin border-b-2 border-t-2 border-snow-white"></div>
            <span>Loading...</span>
          </div>
        ) : (
          clickLabel
        )}
      </button>
    </div>
  );
};

export default AccountButton;
