import React from "react";
import League from "./LeagueOfLegends";
import Valorant from "./Valorant";
// import the lol-logo-rendered-hi-res.png file

const ProfileComponent = () => {
  return (
    <div id="profile" className="m-auto mt-2 flex h-full w-3/5 flex-col">
      <div className="flex h-full flex-col justify-around max-md:gap-4">
        {/* <div className="league flex flex-col rounded-lg h-1/2 bg-cover bg-[url('../public/assets/Seraphine_3_splash.jpg')]"> */}
        <League />
        <Valorant />
      </div>
      {/* <div className="fixed bottom-0 right-0 bg-rich-black bg-opacity-60 p-2 text-center text-snow-white shadow-md shadow-black">
        <p className="m-auto flex items-center justify-center text-xs">
          Meru.gg isn't endorsed by Riot Games and doesn't reflect the views or
          opinions of Riot Games or anyone officially involved in producing or
          managing Riot Games properties. Riot Games, and all associated
          properties are trademarks or registered trademarks of Riot Games, Inc.
        </p>
      </div> */}
    </div>
  );
};

export default ProfileComponent;
