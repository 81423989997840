import React, { useEffect, useState } from "react";

const StatCharts = ({ summonerGraphStats }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    // Set selected index to 0 to start with the first graph
    setSelectedIndex(0);
  }, [summonerGraphStats]);

  if (summonerGraphStats.length === 0) {
    return (
      <div className="m-2 flex h-1/5 justify-center rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 shadow-md shadow-snow-white max-md:flex-col">
        <div className="flex items-center justify-center text-snow-white">
          <p>
            Not enough data yet, let me know and I'll build you a custom tool!
          </p>
        </div>
      </div>
    );
  }

  const changeChart = (direction) => {
    const newIndex =
      direction === "left" ? selectedIndex - 1 : selectedIndex + 1;
    setSelectedIndex(
      (newIndex + summonerGraphStats.length) % summonerGraphStats.length,
    );
  };

  const selectedData = summonerGraphStats[selectedIndex];

  const bgColors = {
    0: "border-snow-white shadow-snow-white",
    1: "border-pastel-cherry-blossom shadow-pastel-cherry-blossom",
    2: "border-pastel-chrysanthemum shadow-pastel-chrysanthemum",
  };

  // Use selected index to determine the border color class
  const borderColorClass = bgColors[selectedData.color];

  // Dynamically import chart component based on query_name
  const ChartComponent = React.lazy(
    () => import(`./Charts/${selectedData.query_name}`),
  );

  return (
    <div
      className={`m-2 flex h-1/3 justify-center rounded-lg border-2 bg-opacity-85 max-md:flex-col ${borderColorClass} bg-rich-black shadow-md`}
    >
      <div className="flex w-full max-lg:flex-col">
        <div className="lg:w-3/5">
          <React.Suspense fallback={<div>Loading...</div>}>
            <ChartComponent data={selectedData.data} />
          </React.Suspense>
        </div>
        <div className="flex flex-col text-snow-white lg:w-2/5">
          <div className="h-3/4">
            <p className="lg:text-md p-2 text-xs">{selectedData.feedback}</p>
          </div>
          <div className="mb-2 flex justify-center gap-4">
            <button
              className="rounded-sm bg-gray-300 px-2 text-rich-black focus:outline-none"
              onClick={() => changeChart("left")}
            >
              &larr;
            </button>
            <span className="text-sm">
              {selectedIndex + 1}/{summonerGraphStats.length}
            </span>
            <button
              className="rounded-sm bg-gray-300 px-2 text-rich-black focus:outline-none"
              onClick={() => changeChart("right")}
            >
              &rarr;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCharts;
