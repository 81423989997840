const timeDifference = (dateUpdated) => {
  const currentDate = new Date();
  const updatedDate = new Date(dateUpdated);

  const differenceInMs = currentDate - updatedDate;
  const differenceInMinutes = Math.round(differenceInMs / (1000 * 60));
  const differenceInHours = Math.round(differenceInMs / (1000 * 60 * 60));
  const differenceInDays = Math.round(differenceInMs / (1000 * 60 * 60 * 24));

  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minutes ago`;
  } else if (differenceInHours < 24) {
    return `${differenceInHours} hours ago`;
  } else {
    return `${differenceInDays} days ago`;
  }
};

export default timeDifference;
