import React from "react";
//import logo from './logo.png';
import ProfileComponent from "../components/ProfileContainer/index.js";

const Profile = () => {
  const isAuthenticated = localStorage.getItem("jwtToken");

  return (
    <>
      {isAuthenticated ? (
        <ProfileComponent />
      ) : (
        <div className="">Please sign in to access your profile</div>
      )}
    </>
  );
};

export default Profile;
