import React from "react";
import ChampStats from "./ChampStats";
import MatchHistory from "./MatchHistory";
import PlayerInfo from "./PlayerInfo";
import StatCharts from "./StatCharts";
const LeagueStatsComponent = ({
  summonerData,
  summonerAggStats,
  summonerMatches,
  summonerGraphStats,
}) => {
  return (
    <div
      id="leaguestats"
      className="m-auto mt-2 flex w-4/5 flex-wrap max-md:flex-col md:justify-center lg:h-full lg:justify-evenly"
    >
      <div
        id="player-champ"
        className="flex w-full flex-col md:w-3/4 lg:w-1/3 xl:w-1/4"
      >
        <PlayerInfo
          account={summonerData.account}
          rankedAccounts={summonerData.ranked_accounts}
          summoner={summonerData.summoner}
        />
        <ChampStats summonerAggStats={summonerAggStats} />
      </div>
      <div
        id="stats-match"
        className="flex h-full w-full flex-col md:w-3/4 lg:w-3/5"
      >
        <StatCharts summonerGraphStats={summonerGraphStats} />
        <MatchHistory summonerMatches={summonerMatches} />
      </div>
      <div className="relative bottom-0 bg-rich-black bg-opacity-60 p-4 text-center text-snow-white shadow-md shadow-black lg:hidden">
        <p className="m-auto flex items-center justify-center text-xs">
          Meru.gg isn't endorsed by Riot Games and doesn't reflect the views or
          opinions of Riot Games or anyone officially involved in producing or
          managing Riot Games properties. Riot Games, and all associated
          properties are trademarks or registered trademarks of Riot Games, Inc.
        </p>
      </div>
    </div>
  );
};

export default LeagueStatsComponent;
