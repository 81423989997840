import React from "react";
//import logo from './logo.png';
import AuthContainer from "../components/AuthContainer/index.js";

// This is theh home page, make two components side by side
// One component is the hero statement and mission
// The other component is the login and register form
const Home = () => {
  return (
    <div className="m-auto flex h-full w-3/4 pt-4 max-lg:flex-col max-lg:gap-4 md:w-3/5 lg:justify-between">
      {/* Hero Section */}
      <div className="flex h-1/4 flex-col justify-around rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 text-white shadow-md shadow-black lg:h-1/2 lg:w-2/5">
        <h1 className="text-center font-bold md:text-2xl">
          Your personal journey in gaming, <br />
          {/* add text below, make it gold, and make it so it looks beautifully centered with the statement above
           */}
          <span className="font-bold text-yellow-500"> Made Easy.</span>
        </h1>
        <p className="break-words text-center text-xs md:text-lg">
          We help you learn and improve your gaming skills, <br />
          by{" "}
          <span className="font-bold text-yellow-500">studying the game</span>,
          so{" "}
          <span className="font-bold text-yellow-500">you don't have to</span>.
        </p>
      </div>
      <AuthContainer />
    </div>
  );
};

export default Home;
