import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getGraphStats,
  getMatchHistory,
  getSummonerAggStats,
  getSummonerProfile,
} from "../components/LeagueStats/api.js";
import LeagueStatsComponent from "../components/LeagueStats/index.js";
import LoadingScreen from "../components/LoadingScreen/index.js";

const LeagueStatsPage = () => {
  const [summonerData, setSummonerData] = useState(null);
  const [summonerAggStats, setSummonerAggStats] = useState(null);
  const [matchHistory, setMatchHistory] = useState([]);
  const [graphStats, setGraphStats] = useState([]);
  const { summonerName } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isPrivatePage, setIsPrivatePage] = useState(false); // New state variable

  const fetchSummonerDetails = async () => {
    setIsLoading(true);
    try {
      // Make API call to fetch summoner details, ranks, and aggregate stats
      const response = await getSummonerProfile(summonerName);
      setSummonerData(response.data);

      // Trigger subsequent API calls once summoner details are fetched successfully
      fetchSummonerAggStats();
      fetchMatchHistory();
      fetchGraphStats();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (error.response.status === 401 || 404) {
        setIsPrivatePage(true); // Set the state variable to true for private page
      }
    }
  };

  const fetchSummonerAggStats = async () => {
    try {
      // Make API call to fetch summoner aggregate stats
      const response = await getSummonerAggStats(summonerName);
      setSummonerAggStats(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMatchHistory = async () => {
    try {
      // Make API call to fetch match history with pagination
      const response = await getMatchHistory(summonerName);
      setMatchHistory(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchGraphStats = async () => {
    try {
      // Make API call to fetch match history with pagination
      const response = await getGraphStats(summonerName);
      console.log(response.data);
      setGraphStats(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSummonerDetails();
  }, [summonerName]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : isPrivatePage ? ( // Check if it's a private page
        <div className="flex h-full items-center justify-center">
          <div className="m-auto flex flex-col items-center rounded-lg border-2 border-red-600 bg-rich-black bg-opacity-60 p-4 text-snow-white shadow-md shadow-black">
            <div className="mb-4"></div>
            <p className="text-snow-white">
              Private Page, please login or request a sign up code if you want
              to view your own custom stats.
            </p>
          </div>
        </div>
      ) : (
        summonerData && (
          <LeagueStatsComponent
            summonerData={summonerData}
            summonerAggStats={summonerAggStats}
            summonerMatches={matchHistory}
            summonerGraphStats={graphStats}
          />
        )
      )}
    </>
  );
};

export default LeagueStatsPage;
