import React from "react";

const Valorant = () => {
  // const [showValorantAddAccount, setShowValorantAddAccount] = useState(false);
  // const jwtToken = localStorage.getItem("jwtToken");

  return (
    <div id="valorant" className="mt-8 flex h-full flex-col rounded-lg">
      <div className="rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 p-2 shadow-md shadow-rich-black backdrop-blur-sm backdrop-filter">
        <div className="flex h-full items-center justify-center text-lg font-bold text-snow-white drop-shadow-md sm:text-2xl">
          Valorant
        </div>
      </div>
      <div className="z-1 mt-1 flex gap-4 max-md:flex-col md:h-5/6">
        <div className="flex h-36 flex-col items-center justify-evenly rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 p-2 shadow-md shadow-rich-black md:h-1/2 md:w-1/4">
          {/* Make a cool "Coming Soon"  */}
          <div id="coming-soon" className="text-2xl font-bold text-snow-white">
            Coming Soon
          </div>
        </div>
      </div>
    </div>
  );
};

export default Valorant;
