import React, { useEffect, useState } from "react";
import spellsjson from "../../../data/summoner_clean.json";
import "./index.css";

const ITEM_BASE_URL =
  "http://ddragon.leagueoflegends.com/cdn/14.10.1/img/item/";
// const SPELL_BASE_URL =
//   "http://ddragon.leagueoflegends.com/cdn/14.10.1/img/spell/";
// const PERKS_BASE_URL = "https://ddragon.leagueoflegends.com/cdn/img/";
const CHAMPION_IMG_BASE_URL =
  "http://ddragon.leagueoflegends.com/cdn/14.10.1/img/champion/";

function formatTimeDifference(timeDifference) {
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else {
    return `${minutes} minutes ago`;
  }
}

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = remainingSeconds.toString().padStart(2, "0");
  return `${paddedMinutes}:${paddedSeconds}`;
}

const MATCHES_PER_PAGE_MOBILE = 5;
const MATCHES_PER_PAGE_DESKTOP = 10;

const MatchHistory = ({ summonerMatches }) => {
  const currentTime = new Date();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [displayedMatches, setDisplayedMatches] = useState([]);

  const matchesPerPage = isMobile
    ? MATCHES_PER_PAGE_MOBILE
    : MATCHES_PER_PAGE_DESKTOP;

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    const initialMatches = summonerMatches.slice(0, matchesPerPage);
    setDisplayedMatches(initialMatches);
    setShowLoadMore(summonerMatches.length > matchesPerPage);
    return () => window.removeEventListener("resize", handleResize);
  }, [summonerMatches, matchesPerPage]);

  const loadMore = () => {
    const nextMatches = summonerMatches.slice(
      displayedMatches.length,
      displayedMatches.length + matchesPerPage,
    );
    setDisplayedMatches((prevMatches) => [...prevMatches, ...nextMatches]);
    setShowLoadMore(
      displayedMatches.length + matchesPerPage < summonerMatches.length,
    );
  };

  if (summonerMatches.length === 0) {
    return (
      <div className="no-data">
        <p>Play some match to get started!</p>
      </div>
    );
  } else {
    return (
      <div id="match-history" className="m-2 flex flex-col items-center">
        <div className="match-history max-h-96 w-full overflow-y-auto">
          {displayedMatches.map((game, index) => (
            <SingleMatch key={index} {...getMatchData(game, currentTime)} />
          ))}
        </div>
        {showLoadMore && (
          <button
            className="mt-4 rounded-lg bg-blue-500 px-4 py-2 text-white"
            onClick={loadMore}
          >
            Load More
          </button>
        )}
      </div>
    );
  }
};

const getMatchData = (game, currentTime) => ({
  game_result: game.player.win,
  queue_type: queue_type(game.match.queueId),
  time: formatTimeDifference(currentTime - game.match.gameEndTimestamp),
  duration: formatTime(game.match.gameDuration),
  championName: game.player.championName,
  items: [
    game.player.item0,
    game.player.item1,
    game.player.item2,
    game.player.item3,
    game.player.item4,
    game.player.item5,
  ],
  ward: [game.player.item6],
  spells: [
    spellsjson[game.player.summoner1Id],
    spellsjson[game.player.summoner2Id],
  ],
  stats: [
    `${
      game.player.deaths === 0
        ? `${game.player.kills + game.player.assists}`
        : `${(
            (game.player.kills + game.player.assists) /
            game.player.deaths
          ).toFixed(2)}`
    }`,
    `${game.player.kills}/${game.player.deaths}/${game.player.assists}`,
    `${game.player.totalMinionsKilled + game.player.neutralMinionsKilled}cs`,
    `${(
      (game.player.totalMinionsKilled + game.player.neutralMinionsKilled) /
      (game.match.gameDuration / 60)
    ).toFixed(2)} CS/m`,
    `${game.player.visionScore}(${game.player.visionWardsBoughtInGame}p)`,
    `${game.player.sightWardsBoughtInGame}y ${game.player.visionWardsBoughtInGame}r ${game.player.detectorWardsPlaced}b`,
  ],
});

const queue_type = (queueId) => {
  const queueTypes = {
    420: "Ranked Solo",
    430: "Blind Pick",
    440: "Ranked Flex",
    450: "ARAM",
    700: "Clash",
    900: "URF",
    1020: "One for All",
    1300: "Nexus Blitz",
    1400: "Ultimate Spellbook",
  };
  return queueTypes[queueId] || "Custom Game";
};

const SingleMatch = (props) => {
  const renderItemImage = (item) => {
    if (item === 0) {
      return <div className="w-10 rounded bg-gray-400 md:h-8 md:w-8"></div>;
    } else {
      const url = `${ITEM_BASE_URL}${item}.png`;
      return (
        <img
          className="w-10 rounded md:h-8 md:w-8"
          src={url}
          alt={`Item ${item}`}
        />
      );
    }
  };

  return (
    <div
      className={`mb-4 rounded-lg border-2 p-4 shadow-md ${
        props.game_result ? "border-green-500" : "border-red-500"
      } bg-rich-black bg-opacity-85 shadow-md shadow-black`}
    >
      <div className="flex flex-col flex-wrap md:justify-between lg:flex-row lg:items-center">
        <button
          title="Coming soon!"
          disabled
          className="rounded border-2 border-pastel-chrysanthemum bg-yellow-500 text-sm font-bold text-rich-black hover:bg-pastel-chrysanthemum disabled:cursor-not-allowed  max-md:p-2 md:p-2 md:text-xs"
        >
          Coach Me!
        </button>
        <div className="mb-4 flex flex-grow items-center justify-evenly md:mb-0">
          <div className="flex w-1/2 items-center text-center max-lg:justify-evenly md:text-left xl:flex-col">
            <div className="flex w-full flex-col">
              <div className="text-sm text-gray-300">{props.queue_type}</div>
              <div className="text-xs text-gray-400">{props.time}</div>
            </div>
            <div className="flex w-full flex-col">
              <div
                className={` text-lg font-bold ${
                  props.game_result ? "text-green-300" : "text-red-300"
                }`}
              >
                {props.game_result ? "Victory" : "Defeat"}
              </div>
              <div className="text-sm text-gray-300">{props.duration}</div>
            </div>
          </div>
          <img
            className="h-16 w-16 rounded-full border-2 border-gray-300"
            src={`${CHAMPION_IMG_BASE_URL}${props.championName}.png`}
            alt="champion"
          />
        </div>
        <div className="flex flex-grow flex-col md:flex-row md:items-center md:justify-between">
          <div className="mb-4 flex flex-wrap justify-center md:mb-0 md:w-1/2">
            <div className="grid grid-cols-3 gap-2">
              {props.items.slice(0, 6).map((item, index) => (
                <React.Fragment key={index}>
                  {renderItemImage(item)}
                </React.Fragment>
              ))}
            </div>
            <div className="ml-2">{renderItemImage(props.ward)}</div>
          </div>
          <div className="md:w-1/2">
            <table className="w-full text-sm text-gray-300">
              <tbody className="">
                <tr className="">
                  <th className="">KDA</th>
                  <td className="">{props.stats[0]}</td>
                  <td className="">{props.stats[1]}</td>
                </tr>
                <tr>
                  <th className="">CS</th>
                  <td className="">{props.stats[2]}</td>
                  <td className="">{props.stats[3]}</td>
                </tr>
                <tr>
                  <th className="">Vision</th>
                  <td className="">{props.stats[4]}</td>
                  <td className="">{props.stats[5]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchHistory;
