import axios from "axios";
import { API_URL } from "../../config";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const signIn = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    localStorage.setItem("jwtToken", response.data.data.access_token);
    return { success: true, message: "Success, redirecting..." };
  } catch (error) {
    return {
      success: false,
      message: "Login Failed, contact your connect for help.",
    };
  }
};

export const signUp = async (formData) => {
  try {
    await api.post(`${API_URL}/auth/create`, {
      payload: formData,
    });
    return { success: true, message: "Account created! Please sign in." };
  } catch (error) {
    if (error.response.status === 403) {
      return {
        success: false,
        message: "Sign up locked. Invites through referrals codes.",
      };
    } else if (error.response.status === 422) {
      return {
        success: false,
        message: "Credentials do not match, try again.",
      };
    }
  }
};
