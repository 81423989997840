import React from "react";
import AccountDetails from "./AccountDetails";

const AccountCard = ({ profile }) => {
  return (
    <div
      id="account-card"
      className="flex h-40 flex-col items-center justify-evenly rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 p-2 shadow-md shadow-rich-black md:h-3/4 md:w-1/2 md:flex-wrap lg:w-1/3"
    >
      <AccountDetails account={profile} />
    </div>
  );
};

export default AccountCard;
