import React, { useState } from "react";
import AccountButton from "./AccountButton";
import DropdownForm from "./DropdownForm";
import TextForm from "./TextForm";
import {
  addAccount,
  addSummonerAccount,
  addSummonerMatchHistory,
  addSummonerRankedAccount,
} from "./api";

const AddAccount = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [textValue, setTextValue] = useState("");
  const [tagLine, setTagLine] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleTagLineChange = (event) => {
    setTagLine(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage("Adding account...");

    const payload = {
      gameName: textValue,
      tagLine: tagLine,
      region: selectedOption,
    };

    // prevent payload from being sent if any of the fields are empty
    if (!payload.gameName || !payload.tagLine || !payload.region) {
      setStatusMessage("All fields are required");
      return;
    }

    try {
      await addAccount(payload);
      setStatusMessage("Account created successfully.");
      setTimeout(() => {
        setStatusMessage("Creating summoner account...");
      }, 2000); // 2 seconds delay

      await addSummonerAccount(textValue);
      setStatusMessage("Summoner account created successfully.");
      setTimeout(() => {
        setStatusMessage("Creating ranked account...");
      }, 2000); // 2 seconds delay

      try {
        await addSummonerRankedAccount(textValue);
        setStatusMessage("Ranked account created successfully.");
      } catch (error) {
        setStatusMessage("Failed to create ranked account.");
        console.error("Error creating ranked account:", error);
      }

      setTimeout(() => {
        setStatusMessage("Adding match history...");
      }, 2000); // 2 seconds delay

      try {
        await addSummonerMatchHistory(textValue);
        setStatusMessage("Match history connected.");
      } catch (error) {
        setStatusMessage("Failed to add match history.");
        console.error("Error adding match history:", error);
      }

      setTextValue("");
      setTagLine("");
      setSelectedOption("");

      setTimeout(() => {
        setStatusMessage("Downloading and creating custom feedback.");
      }, 2000); // 2 seconds delay

      setTimeout(() => {
        window.location.reload();
      }, 5000); // 5 seconds delay for page reload
    } catch (error) {
      setStatusMessage(error.response?.data?.detail || "Failed to add account");
    }
  };
  return (
    <div className="flex h-40 flex-col items-center justify-evenly rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 p-2 shadow-md shadow-rich-black md:h-3/4 md:w-1/2 lg:w-1/3">
      <div className="text-2xl font-bold text-snow-white">Add Account</div>
      <form
        className="flex h-2/5 flex-col justify-evenly"
        onSubmit={handleSubmit}
        method="post"
      >
        <div className="flex">
          <DropdownForm
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
          />
          <TextForm
            textValue={textValue}
            handleTextChange={handleTextChange}
            tagLine={tagLine}
            handleTagLineChange={handleTagLineChange}
          />
        </div>

        <div className="flex w-full justify-around">
          <AccountButton
            clickLabel={"Add"}
            handleClick={handleSubmit}
            twClass={
              "flex w-1/4 rounded-sm bg-earth-blue text-xs lg:text-sm font-bold  text-snow-white justify-center"
            }
          />
        </div>
      </form>

      {statusMessage && (
        <div className="text-pretty rounded-sm p-1 text-center font-bold text-snow-white">
          {statusMessage}
        </div>
      )}
    </div>
  );
};

export default AddAccount;
