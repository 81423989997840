import React, { useEffect, useState } from "react";
import AccountCard from "../AccountCard";
import AddAccount from "../AddAccount";
import { fetchProfile } from "../api";

const League = () => {
  const [leagueProfiles, setLeagueProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetchProfile();
        setLeagueProfiles(response.data); // The response is now an array of league profiles
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setLeagueProfiles([]);
        setIsLoading(false);
      }
    };
    fetchProfileData();
  }, []);

  return (
    <div id="league" className="flex h-full flex-col rounded-lg">
      <div className="mt-4 rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 p-2 shadow-md shadow-rich-black backdrop-blur-sm backdrop-filter">
        <div className="flex h-full items-center justify-center text-lg font-bold text-snow-white drop-shadow-md sm:text-2xl">
          League of Legends
        </div>
      </div>
      <div className="z-1 mt-1 flex gap-4 max-md:flex-col md:h-5/6">
        {isLoading ? (
          <div className="flex h-40 flex-col items-center justify-evenly rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 p-2 shadow-md shadow-rich-black md:h-3/4 md:w-1/2 md:flex-wrap lg:w-1/3">
            <div className="flex h-16 w-16 animate-spin items-center justify-center rounded-full border-b-2 border-t-2 border-snow-white"></div>
          </div>
        ) : leagueProfiles.length === 0 ? (
          <AddAccount />
        ) : (
          <>
            {leagueProfiles.slice(0, 3).map((profile, index) => (
              <AccountCard key={index} profile={profile} />
            ))}
            {leagueProfiles.length < 3 && <AddAccount />}
          </>
        )}
      </div>
    </div>
  );
};

export default League;
