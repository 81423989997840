import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountButton from "./AccountButton";
import timeDifference from "./TimeDifference";
import { deleteUserRiotAccount, updateSummoner } from "./api";

const AccountDetails = ({ account }) => {
  const [updateMessages, setUpdateMessages] = useState({});
  const [deletingAccounts, setDeletingAccounts] = useState({});
  const navigate = useNavigate();

  const updateSummonerAccount = async (gameName) => {
    try {
      setUpdateMessages((prevState) => ({
        ...prevState,
        [gameName]: `Updating ${gameName}`,
      }));
      const response = await updateSummoner(gameName);
      setUpdateMessages((prevState) => ({
        ...prevState,
        [gameName]: response.detail,
      }));
    } catch (error) {
      console.error(error);
      setUpdateMessages((prevState) => ({
        ...prevState,
        [gameName]:
          error.response?.data?.detail ||
          "Failed to update, contact your connect for help.",
      }));
    }

    setTimeout(() => {
      setUpdateMessages((prevState) => ({
        ...prevState,
        [gameName]: "",
      }));
      window.location.reload();
    }, 3000);
  };

  const deleteAccount = async (gameName) => {
    try {
      setDeletingAccounts((prevState) => ({
        ...prevState,
        [gameName]: true,
      }));
      await deleteUserRiotAccount(gameName);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (error) {
      console.error(error);
      setDeletingAccounts((prevState) => ({
        ...prevState,
        [gameName]: false,
      }));
    }
  };

  return (
    <>
      <div className="flex items-center justify-center gap-2 text-snow-white">
        <div className="text-center text-2xl font-bold">
          {account.riot_account.gameName}
        </div>
        <div className="account-tagline text-2xl font-bold">
          #{account.riot_account.tagLine}
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-start text-snow-white">
        {account.summoner_account && (
          <div className="summoner-last-updated text-sm font-bold">
            Last Updated:{" "}
            {account.summoner_account.leagueMatchUpdated === 0
              ? "Data not pulled"
              : timeDifference(account.summoner_account.leagueMatchUpdated)}
          </div>
        )}
      </div>
      <div className="flex w-full justify-evenly text-xs text-snow-white md:font-bold xl:text-sm">
        <AccountButton
          clickLabel={"View"}
          twClass={"flex w-1/4 bg-earth-blue rounded-sm"}
          handleClick={() =>
            navigate(`/leaguestats/${account.riot_account.gameName}`)
          }
        />
        <AccountButton
          clickLabel={"Update"}
          handleClick={() =>
            updateSummonerAccount(account.riot_account.gameName)
          }
          twClass={"flex w-1/4 rounded-sm bg-earth-pink"}
        />
        <AccountButton
          clickLabel={"Delete"}
          handleClick={() => deleteAccount(account.riot_account.gameName)}
          twClass={"flex w-1/4 rounded-sm bg-c-red"}
          isLoading={deletingAccounts[account.riot_account.gameName]}
        />
      </div>
      {updateMessages[account.riot_account.gameName] && (
        <div className="text-xl text-snow-white">
          {updateMessages[account.riot_account.gameName]}
        </div>
      )}
    </>
  );
};

export default AccountDetails;
