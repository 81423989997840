import React from "react";
// import { NavLink, NavMenu }
//     from "./NavbarElements";
import { NavLink } from "react-router-dom";
import logo from "./logo.png";

const NavBar = () => {
  return (
    <div
      id="navbar"
      className="m-auto flex h-full w-3/5 flex-row items-center rounded-b-lg border-x-2 border-b-2 border-snow-white bg-rich-black bg-opacity-85 text-snow-white max-lg:justify-evenly"
    >
      <NavLink
        className={({ isActive }) =>
          "text-2xl font-bold md:w-1/4 md:text-center md:text-5xl"
        }
        to="/"
        exact="false"
      >
        Meru
      </NavLink>
      <div
        id="nav-links"
        className="max-lg:order-2 max-md:justify-center md:flex md:w-1/6"
      >
        {links.map((link) => (
          <NavLink
            key={link.to}
            to={link.to}
            className={({ isActive }) =>
              "font-bold transition duration-300 ease-in-out hover:underline md:text-2xl" +
              (isActive ? " text-earth-blue underline" : "")
            }
            exact="false"
          >
            {link.label}
          </NavLink>
        ))}
      </div>
      <img
        className="h-24 md:mr-6 md:h-28 lg:h-32 xl:ml-8 xl:h-36"
        src={logo}
        alt="logo"
      />
      {/* <NavLink
        className={({ isActive }) =>
          "w-1/5 text-center text-3xl font-bold text-pastel-cherry-blossom"
        }
        to="/learn"
        exact="false"
      >
        Learn
      </NavLink> */}

      {/* <SearchBar /> */}
    </div>
  );
};

// const SearchBar = () => {
//   return (
//     <div className="SearchBar flex flex-grow items-center justify-end px-4">
//       <form className="SearchForm flex items-center rounded-lg shadow-md">
//         <input
//           type="text"
//           placeholder="Search Summoner Name"
//           className="SearchText rounded-lg p-2 text-rich-black shadow-lg"
//         />
//       </form>
//     </div>
//   );
// };

const links = [
  { to: "/profile", label: "Profile" },
  // { to: "/leaguestats", label: "Stats" },
  // { to: "/about", label: "About" },
];

export default NavBar;
