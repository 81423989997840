export const LoadingDots = ({ customClass }) => {
  // customColor is a tailwindcss color class, default to "bg-snow-white"
  customClass = customClass || "bg-snow-white";
  return (
    <div className="flex items-center justify-center gap-1 p-2">
      <div
        className={`flex h-2 w-2 animate-bounce items-center justify-center rounded-full ${customClass} animation-delay-100`}
      ></div>
      <div
        className={`flex h-2 w-2 animate-bounce items-center justify-center rounded-full ${customClass} animation-delay-150`}
      ></div>
      <div
        className={`flex h-2 w-2 animate-bounce items-center justify-center rounded-full ${customClass} animation-delay-200`}
      ></div>
    </div>
  );
};
