const TextForm = ({
  textValue,
  handleTextChange,
  tagLine,
  handleTagLineChange,
}) => {
  return (
    <div className="text-form flex w-3/4 justify-around text-xs">
      <input
        type="text"
        className=" w-1/2 rounded-sm p-1"
        id="game-name"
        placeholder="Game Name"
        value={textValue}
        onChange={handleTextChange}
        required
      />
      <input
        type="text"
        className="w-1/4 rounded-sm p-1 text-xs"
        id="tag-line"
        placeholder="#NA1"
        value={tagLine}
        onChange={handleTagLineChange}
        required
      />
    </div>
  );
};

export default TextForm;
