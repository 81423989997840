const DropdownForm = ({ selectedOption, handleOptionChange }) => {
  return (
    <div className="flex w-1/4 text-xs">
      <select
        className="flex w-full rounded-sm"
        value={selectedOption}
        onChange={handleOptionChange}
        required
      >
        <option value="">Region</option>
        <option value="americas">North America</option>
        {/* <option value="EU1">Europe</option>
            <option value="KR1">Korea</option> */}
      </select>
    </div>
  );
};

export default DropdownForm;
