import React, { useEffect, useState } from "react";
import "./index.css";
const CHAMPION_IMG_BASE_URL =
  "http://ddragon.leagueoflegends.com/cdn/14.3.1/img/champion/";
const ROLE_IMG_BASE_URL =
  "https://raw.githubusercontent.com/InFinity54/LoL_DDragon/master/extras/lanes/";

const ChampStats = ({ summonerAggStats }) => {
  const [loaded, setLoaded] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      setLoaded(window.innerWidth <= 1024 ? 5 : 5);
    };
    handleResize(); // Set initial value based on window size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="m-2 rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 text-snow-white shadow-md">
      <section className="sticky flex flex-col items-center">
        <h1 className="font-bold md:text-lg">Champion Stats</h1>
      </section>
      <div
        id="champions"
        className="flex flex-col overflow-y-auto max-md:h-[124px]"
      >
        {summonerAggStats ? (
          summonerAggStats.length > 0 ? (
            summonerAggStats
              .slice(0, loaded)
              .map((summonerAggStat, index) => (
                <ChampionData
                  key={index}
                  summerAggStat={summonerAggStat.stats}
                />
              ))
          ) : (
            <div className="no-data">
              <p>Play some matches to get started!</p>
            </div>
          )
        ) : (
          <div className="text-center">
            <p>No matches played this season!</p>
          </div>
        )}
      </div>
      <div className="relative bottom-0 bg-rich-black bg-opacity-60 p-4 text-center text-snow-white shadow-md shadow-black max-lg:hidden">
        <p className="m-auto flex items-center justify-center text-xs">
          Meru.gg isn't endorsed by Riot Games and doesn't reflect the views or
          opinions of Riot Games or anyone officially involved in producing or
          managing Riot Games properties. Riot Games, and all associated
          properties are trademarks or registered trademarks of Riot Games, Inc.
        </p>
      </div>
    </div>
  );
};

const ChampionData = ({ summerAggStat }) => {
  const {
    assists,
    champion,
    deaths,
    gamesPlayed,
    kdr,
    kills,
    losses,
    roles,
    win_rate,
    wins,
  } = summerAggStat;

  const roleMap = {
    TOP: "top",
    JUNGLE: "jungle",
    MIDDLE: "mid",
    CARRY: "bottom",
    BOTTOM: "bottom",
    UTILITY: "support",
  };

  return (
    <div className="flex items-center justify-between p-2 text-xs md:text-sm">
      <div className="flex h-8 w-8 items-center">
        <img
          className="rounded-lg"
          src={`${CHAMPION_IMG_BASE_URL}${champion}.png`}
          alt="champion"
        />
      </div>
      <div className="flex items-center justify-start gap-2">
        <img
          className="h-4 w-4"
          src={`${ROLE_IMG_BASE_URL}${roleMap[roles]}.png`}
          alt="roles"
        />
        <p className="truncate font-bold">{champion}</p>
        <p className="w-1/5 text-center">{`(${gamesPlayed})`}</p>
      </div>
      <div className="flex w-1/2 flex-col justify-center">
        <div className="flex justify-around">
          <p className="">{`${(win_rate * 100).toFixed(0)}%`}</p>
          <p className="">{`${wins}W - ${losses}L`}</p>
        </div>
        <div className="flex justify-around">
          <p className="">{kdr}</p>
          <p className="">
            {kills}/{deaths}/{assists}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChampStats;
