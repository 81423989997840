import axios from "axios";
import { API_URL } from "../../config";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the JWT token in the headers
api.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export const updateSummoner = async (summonerName) => {
  try {
    const response = await api.post(`/lol/summoner/v1/update/${summonerName}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchProfile = async () => {
  try {
    const response = await api.get(`/lol/summoner/v1/profile`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addAccount = async (payload) => {
  try {
    const response = await api.post(`/lol/summoner/v1/account`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSummonerAccount = async (summonerName) => {
  try {
    const response = await api.get(
      `/lol/summoner/v1/create-summoner-account/${summonerName}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSummonerRankedAccount = async (summonerName) => {
  try {
    const response = await api.post(
      `/lol/summoner/v1/create-ranked-accounts/${summonerName}`,
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addSummonerMatchHistory = async (summonerName) => {
  try {
    const response = await api.post(
      `/lol/summoner/v1/create-match-history/${summonerName}`,
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to add match history");
  }
};

export const deleteUserRiotAccount = async (summonerName) => {
  try {
    const response = await api.delete(
      `/lol/summoner/v1/delete/${summonerName}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error to handle it in the frontend
  }
};
