import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages";
import LeagueStatsPage from "./pages/leaguestats";
import NotFoundPage from "./pages/notfound.js";
import Profile from "./pages/profile.js";

function App() {
  /*Adds title name to all components and pages.*/
  useEffect(() => {
    document.title = "Meru AI";
  }, []);

  return (
    <>
      <Router>
        <div className="min-h-full min-w-full bg-[image:url('../public/images/home_3.png')] bg-cover bg-center bg-no-repeat brightness-[.97] max-lg:overflow-y-auto max-md:bg-scroll">
          <div className="h-28">
            <Navbar />
          </div>
          <div className="h-[calc(100vh-128px)]">
            <Routes>
              <Route exact path="/" element={<Home />} />
              {/* <Route path="/about" element={<About />} /> */}
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/leaguestats/:summonerName"
                element={<LeagueStatsPage />}
              />
              <Route path="*" element={<NotFoundPage />} /> {/* 404 route */}
              {/* <Route path="/learn" element={<Learn />} /> */}
            </Routes>
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
