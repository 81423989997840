// LoadingScreen.js
import React from "react";
import "./index.css";
const LoadingScreen = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="loader mb-4"></div>
        <p className="text-snow-white">Loading...</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
