import React from "react";

const PROFILE_ICON_BASE_URL =
  "http://ddragon.leagueoflegends.com/cdn/14.3.1/img/profileicon/";
const TIER_IMAGE_BASE_URL =
  "https://raw.githubusercontent.com/InFinity54/LoL_DDragon/master/extras/tier/";

// mapping constant to convert queueType to a more readable format
const queueTypeMap = {
  RANKED_SOLO_5x5: "Ranked Solo",
  RANKED_FLEX_SR: "Ranked Flex",
};

const PlayerInfo = ({ account, rankedAccounts, summoner }) => {
  const rankMap = { I: 1, II: 2, III: 3, IV: 4 };

  function capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <div
      id="player-info"
      className="m-2 flex flex-col rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 text-snow-white shadow-md shadow-black"
    >
      <div id="profile" className="flex justify-evenly p-2 lg:flex-col">
        <div
          id="icon-name"
          className="flex justify-between max-md:w-1/2 max-sm:flex-col"
        >
          <div className="flex flex-col items-center">
            <img
              id="profile-icon"
              className="h-10 w-10 rounded-lg md:h-20 md:w-20"
              src={`${PROFILE_ICON_BASE_URL}${summoner.profileIconId}.png`}
              alt={`${account.gameName}'s profile`}
            />
            <p className="-mt-2 rounded-lg bg-rich-black p-1 text-center text-[10px] md:text-sm">
              {summoner.summonerLevel}
            </p>
          </div>
          <div className="flex flex-col justify-center">
            <p className="truncate text-center font-bold">
              {account.gameName} #{account.tagLine}
            </p>
          </div>
        </div>
        <div className="flex flex-col text-sm max-md:w-1/2">
          {rankedAccounts.length > 0 ? (
            rankedAccounts.map((rankedAccount, idx) => {
              const { tier } = rankedAccount;
              return (
                <div key={idx} className="flex flex-col justify-between p-2">
                  <div className="flex justify-between">
                    <img
                      className="h-8 rounded-lg md:h-12"
                      src={`${TIER_IMAGE_BASE_URL}${tier.toLowerCase()}.png`}
                      alt={`${tier} player`}
                    />
                    {/* Map the queueType to the constant */}
                    <p className="text-md self-center font-bold">
                      {queueTypeMap[rankedAccount.queueType]}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-md font-bold">{`${capitalizeString(
                      tier,
                    )} ${rankMap[rankedAccount.rank]}`}</p>
                    <p className="league-points-text">{`${rankedAccount.leaguePoints} LP`}</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="">{`${rankedAccount.wins}W - ${rankedAccount.losses}L`}</p>
                    <p className="">{`${(
                      (rankedAccount.wins /
                        (rankedAccount.wins + rankedAccount.losses)) *
                      100
                    ).toFixed(1)}%`}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="currentRank flex justify-around">
              No ranked accounts yet!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerInfo;
