import React, { useState } from "react";
import { LoadingDots } from "../LoadingDots";
import { signIn, signUp } from "./api";
import "./index.css";

const AuthComponent = () => {
  const [apiResponse, setApiResponse] = useState({
    message: "",
    success: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirm_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = e.target;
    const formData = new FormData(form);
    try {
      const response = isSignIn
        ? await signIn(formData)
        : await signUp(Object.fromEntries(formData));

      setApiResponse(response);

      if (response.success && isSignIn) {
        setTimeout(() => {
          window.location.href = "/profile";
        }, 1500);
      } else if (response.success) {
        setTimeout(() => {
          toggleMode();
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  // Toggle between sign-in and sign-up modes
  const toggleMode = () => {
    setIsSignIn(!isSignIn);
    setFormData({
      username: "",
      password: "",
      confirm_password: "",
    });
    setShowPassword(false); // Reset showPassword state
    setShowConfirmPassword(false); // Reset showConfirmPassword state
    setApiResponse({
      message: "",
      success: null,
    });
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <div className="flex h-1/2 justify-center lg:w-2/5">
      <div className="w-full rounded-lg border-2 border-snow-white bg-rich-black bg-opacity-85 text-white shadow-md shadow-black">
        <h1 className="h-1/5 text-center text-2xl font-bold text-white lg:py-4 lg:text-4xl">
          {isSignIn ? "Sign In" : "Sign Up"}
        </h1>
        <form className="flex h-3/5 flex-col px-2" onSubmit={handleSubmit}>
          <div className="flex h-3/4 flex-col gap-2 py-4">
            <div className="flex justify-center">
              <input
                type="text"
                name="username"
                placeholder="Username"
                autoComplete="username"
                value={formData.username}
                onChange={handleChange}
                required
                className="rounded-md bg-gray-900 px-2 py-1 text-white focus:border-earth-blue focus:outline-none focus:ring"
              />
            </div>
            <div className="relative flex justify-center">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                required
                className="rounded-md bg-gray-900 px-2 py-1 text-white focus:border-earth-blue focus:outline-none focus:ring"
              />
              {/* <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-16 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300 md:right-6 lg:right-20"
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                      clipRule="evenodd"
                    />
                    <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path
                      fillRule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button> */}
            </div>
            {!isSignIn && (
              <div className="relative flex justify-center">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                  required
                  value={formData.confirm_password}
                  onChange={handleChange}
                  className="rounded-md bg-gray-900 px-2 py-1 text-white focus:border-earth-blue focus:outline-none focus:ring"
                />
                {/* <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute right-16 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300 md:right-6 lg:right-20"
                >
                  {showConfirmPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                        clipRule="evenodd"
                      />
                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </button> */}
              </div>
            )}
          </div>

          <div className="flex flex-col items-center">
            <button
              type="submit"
              disabled={isLoading}
              className={`w-1/3 rounded-md bg-earth-blue p-2 text-white hover:bg-opacity-90 focus:border-earth-blue focus:outline-none focus:ring lg:w-1/2 ${
                isLoading ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              {isLoading ? <LoadingDots /> : isSignIn ? "Sign In" : "Sign Up"}
            </button>
            <div className="flex flex-col items-center justify-evenly p-2">
              <p className="text-sm text-snow-white">
                {isSignIn
                  ? "Don't have an account?"
                  : "Already have an account?"}
              </p>
              <button
                type="button"
                onClick={toggleMode}
                className="rounded-md font-bold text-pastel-cherry-blossom underline hover:text-opacity-90 focus:outline-none focus:ring"
              >
                {isSignIn ? "Sign Up" : "Sign In"}
              </button>
            </div>
          </div>
        </form>
        <div id="response" className="text-center">
          <p
            className={
              apiResponse.success === true
                ? "text-pastel-cherry-blossom"
                : "font-extrabold text-pastel-chrysanthemum"
            }
          >{`${apiResponse.message}`}</p>
        </div>
      </div>
      <div className="sm:left-1/5 sm:right-1/5 fixed bottom-0 bg-rich-black bg-opacity-60 p-4 text-center text-snow-white shadow-md shadow-black md:left-1/4 md:right-1/4">
        <p className="m-auto flex items-center justify-center text-xs">
          Meru.gg isn't endorsed by Riot Games and doesn't reflect the views or
          opinions of Riot Games or anyone officially involved in producing or
          managing Riot Games properties. Riot Games, and all associated
          properties are trademarks or registered trademarks of Riot Games, Inc.
        </p>
      </div>
    </div>
  );
};

export default AuthComponent;
