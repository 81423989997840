import axios from "axios";
import { API_URL } from "../../config";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the JWT token in the headers
api.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export const getSummonerProfile = async (summonerName) => {
  try {
    const response = await api.get(`/lol/summoner/v1/profile/${summonerName}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSummonerAggStats = async (summonerName) => {
  try {
    const response = await api.get(`/lol/summoner/v1/stats/${summonerName}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getMatchHistory = async (summonerName) => {
  try {
    const response = await api.get(`/lol/summoner/v1/matches/${summonerName}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getGraphStats = async (summonerName) => {
  try {
    const response = await api.get(`/lol/summoner/v1/graph/${summonerName}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
